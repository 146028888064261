import React from "react";
// import backgroundTop from "../images/services/feature-bg.jpg"; // Import the image correctly

function OurServices() {
  return (
    <>
<div className="p-5"></div>
      <section
        className="hero-section-two pt-5">
        <div className="container">
          <div className="row align-items-stretch justify-content-center">
            {/* DATA Center Infrastructure Card */}
            <div className="col-12 col-md-4 mb-4">
              <div className="card h-100 shadow">
                <div className="card-body d-flex flex-column">
                  <div className="row w-100">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-start">
                      <i className="fa fa-tasks fa-4x"></i>
                    </div>
                    <div className="col-md-9 col-sm-12">
                      <h5 className="card-title">DATA Center Infrastructure</h5>
                      <p className="card-text">
                        SCDP - Infrastructure support provides clients and agencies
                        with the most scalable, feasible, and reliable infrastructure
                        possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Cyber Security Card */}
            <div className="col-12 col-md-4 mb-4">
              <div className="card h-100 shadow">
                <div className="card-body d-flex flex-column">
                  <div className="row w-100">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-start">
                      <i className="fa fa-lock fa-4x"></i>
                    </div>
                    <div className="col-md-9 col-sm-9">
                      <h5 className="card-title">Cyber Security</h5>
                      <p className="card-text">
                        SCDP - Cyber Security Solution provides customers with
                        Real-Time Awareness, Enterprise Visibility, and Behavior
                        Analytics to reduce operational costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* IT Collaboration Card */}
            <div className="col-12 col-md-4 mb-4">
              <div className="card h-100 shadow">
                <div className="card-body d-flex flex-column">
                  <div className="row w-100">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-start">
                      <i className="fa fa-tty fa-4x"></i>
                    </div>
                    <div className="col-md-9 col-sm-9">
                      <h5 className="card-title">I.T. Collaboration</h5>
                      <p className="card-text">
                        SCDP - I.T. Collaboration practices specialize in calling,
                        meetings, virtual assistance, messaging, as well as Audio
                        Visual Design and Integration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Managed and Other Services Card */}
            <div className="col-12 col-md-4 mb-4">
              <div className="card h-100 shadow">
                <div className="card-body d-flex flex-column">
                  <div className="row w-100">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-start">
                      <i className="fa fa-cogs fa-4x"></i>
                    </div>
                    <div className="col-md-9 col-sm-9">
                      <h5 className="card-title">Managed and Other Services</h5>
                      <p className="card-text">
                        SCDP - Managed Services comprises a complete array of
                        client-based business outcomes and Services Level
                        Agreements (SLAs).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Product Line Card */}
            <div className="col-12 col-md-4 mb-4">
              <div className="card h-100 shadow">
                <div className="card-body d-flex flex-column">
                  <div className="row w-100">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-start">
                      <i className="fa fa-suitcase fa-4x"></i>
                    </div>
                    <div className="col-md-9 col-sm-9">
                      <h5 className="card-title">Product Line</h5>
                      <p className="card-text">
                        SCDP - Hosting a wide range of I.T Hardware and Software
                        Products to enable you to run your business successfully
                        and without interruption.
                      </p>
                      {/* Uncomment to add link */}
                      {/* <a href="#" className="btn btn-link">Request Product Line <span>&#8594;</span></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="p-5"></div>
    </>
  );
}

export default OurServices;
