import React from "react";
import backgroundTop from '../images/hero/top_bg.jpg'

function HeaderMain() {
  return (
    <>
      <section
        class="hero-section-two bgs-cover overlay"
        style={{ backgroundImage: `url(${backgroundTop})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-10 col-sm-11">
              <div class="hero-content text-white py-220 rpy-120">
                <h1 class="wow fadeInUp delay-0-4s mt-20">
                  YOUR TRUSTED <br />
                  TECHNOLOGY <br />
                  PROVIDER
                </h1>
                <span class="sub-title d-block wow fadeInUp delay-0-2s">
                  We make technology accessible to YOU!
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeaderMain;
