import React, { useState, useEffect } from 'react';  
import { Link } from 'react-router-dom';
import logo from "../images/logos/logo_ucraft.png";  // Ensure the logo path is correct

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // To manage menu open/close state

  // Function to check if the page is scrolled
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Add and remove scroll event listener on component mount and unmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Toggle menu function
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu on link click
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className='container'>
        <img className="logo" src={logo} alt="Logo" />
        
        <div 
          className={`menu-toggle ${isOpen ? 'open' : ''}`} 
          onClick={toggleMenu}
        >
          <span className="bar"></span>  
          <span className="bar"></span>  
          <span className="bar"></span>  
        </div>
        
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
            <li><Link to="/" onClick={closeMenu}>HOME</Link></li>
            <li><Link to="/services" onClick={closeMenu}>SERVICES</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>CONTACT</Link></li>
        </ul>
      </div>
    </div>
  );
};  

export default Navbar;
