import React from "react";
import Navbar from "./components/includes/Navbar";
// import Navbar from "./components/includes/Navigation";
import Home from "./components/pages/home";
import Services from "./components/pages/services";
import Contact from "./components/pages/contact";
import Footer from "./components/includes/Footer";
import ScrollToTop from './components/includes/BackToTopCom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollPage from "./hook/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollPage />
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
        <ScrollToTop />
      </div>
    </Router>
  );
}

export default App;
