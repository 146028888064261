import React from "react";
// Import the images at the top of your file
import aboutus1 from "../images/about/about_us_1.jpg";
import aboutus2 from "../images/about/about_us_2.jpg";

function Aboutus() {
  return (
    <>
      <section class="about-three py-60 rpy-50">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s">
                <img src={aboutus1} alt="About" />
                <img src={aboutus2} alt="About" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
                <div class="section-title mb-35">
                  <h2>About Us</h2>
                </div>
                <p>
                SC Discount Promotion has had its humble beginning with a
                  vision “engage people to enable technology into value”. We
                  help you and your business connect with customers, integrate
                  with vendors and empower employees to work to their fullest
                  potential. Our committed teams have a unique blend of
                  functional &amp; operational knowledge, technical expertise
                  and result-oriented management experience. We are women owned
                  small company, proudly registered in Maryland. The people
                  behind this business have been significant and they stand as
                  the pillar of success. Our team consist of players vetted in
                  the US federal and commercial space. With over 10 plus years’
                  experience as Federal Account Specialist’s and Business
                  development coach for our clients, we continue to focus on
                  customer satisfaction and quality workmanship. Our belief is
                  “Customer is Sovereign”, “Customer is the King” and we take
                  this very seriously and endeavor to offer our clients the best
                  in technology with passion to exceed their expectation. We
                  take pride in mentioning that we are tiered with the best in
                  supply chain allowing us to offer a varied range of IT HW/SW,
                  Services and Consultation proposing super competitive pricing.
                  Our technical support and logistic team are all-inclusive and
                  provide a wide range of turnkey solutions. Whether you are a
                  home user or run a small business, we make sure that your
                  hardware/software functions at its optimum and orders are
                  delivered timely. Our consultants aim to minimize your down
                  time, by analyzing your existing environment and mission
                  critical systems carefully before providing a solution that is
                  most fitting to your needs. Our narrative is "CUTOMER
                  SATISFACTION ”, we are not reinventing the wheel, but we
                  employ our devoted efforts to bring forth the latest in
                  technology to ensure that our clientele’s IT infrastructure is
                  ahead of the game, equipped with latest in advancement and
                  Innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutus;
