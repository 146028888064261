import React from "react";
import HeaderContact from "../includes/HeaderContact";
import ContactUs from '../../components/includes/contactus';


function contact (){
    return (
        <>
        <HeaderContact />
        <ContactUs />
        </>
    );
}

export default contact;