import React from "react";
import Slider from "react-slick";
import item1 from "../images/gallery/item-1.jpg";
import item2 from "../images/gallery/item-2.jpg";
import item3 from "../images/gallery/item-3.jpg";
import item4 from "../images/gallery/item-4.jpg";
import item5 from "../images/gallery/item-5.jpg";
import item6 from "../images/gallery/item-6.jpg";

// Import slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function ItemSlider() {
  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Loop infinitely
    speed: 500, // Transition speed in ms
    slidesToShow: 3, // Number of items to show at once on large screens
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024, // For screen widths under 1024px
        settings: {
          slidesToShow: 2, // Show 2 items on smaller screens
        },
      },
      {
        breakpoint: 600, // For screen widths under 600px
        settings: {
          slidesToShow: 1, // Show 1 item on very small screens
        },
      },
    ],
  };

  return (
    <section className="gallery-section-three bg-light mt-25">
      <div className="container-slider">
        <div className="section-title gallery-section-title mb-25 wow fadeInUp delay-0-2s">
          <div className="container mb-5">
            <h2 className="text-center mt-5">Our Partners</h2>
            <Slider {...settings}>
              <div className="item">
                <img
                  src={item1}
                  alt="Item 1"
                  className="slider-item-image"
                />
              </div>
              <div className="item">
                <img
                  src={item2}
                  alt="Item 2"
                  className="slider-item-image"
                />
              </div>
              <div className="item">
                <img
                  src={item3}
                  alt="Item 3"
                  className="slider-item-image"
                />
              </div>
              <div className="item">
                <img
                  src={item4}
                  alt="Item 4"
                  className="slider-item-image"
                />
              </div>
              <div className="item">
                <img
                  src={item5}
                  alt="Item 5"
                  className="slider-item-image"
                />
              </div>
              <div className="item">
                <img
                  src={item6}
                  alt="Item 6"
                  className="slider-item-image"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ItemSlider;
