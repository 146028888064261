import React from "react";
// Import the images at the top of your file
import clientGovtImage from "../images/clients/client_govt.jpg";
import clientCommercialImage from "../images/clients/client_commercial.jpg";

function Clients() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2"></div>
              <div className="col-lg-8 col-md-8 col-sm-8">
                <h2 className="text-center">FEATURED CLIENTS</h2>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2"></div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-3">
                {/* Use the imported image here */}
                <img
                  src={clientGovtImage}
                  alt="Government Federal Client"
                  className="client-image"
                />
                <h3 className="text-center">Government | Federal</h3>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 p-3">
                {/* Use the imported image here */}
                <img
                  src={clientCommercialImage}
                  alt="Commercial Client"
                  className="client-image"
                />
                <h3 className="text-center">Commercial</h3>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
