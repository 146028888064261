import React from "react";
import HeaderServe from '../includes/HeaderServe';
import Services from '../includes/ourServices';

function Serve (){
    return (
        <>
        <HeaderServe />
        <Services />
        
        </>
    );
}

export default Serve;