import React from "react";
// You can import images at the top if needed
// import aboutus1 from "../images/about/about_us_1.jpg";
// import aboutus2 from "../images/about/about_us_2.jpg";

function Contact() {
  return (
    <>
      <section className="ftco-section contact-section pb-50">
        <div className="container">
          <div className="row d-flex mb-5 contact-info">
            <div className="col-md-12 text-center">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="border w-100 h-100 p-4 rounded mb-2 d-flex text-center align-items-center justify-content-center shadow">
                    <p>
                      <i className="fa fa-map-o fa-3x mb-3"></i>
                      <br />
                      
                      211 TESSING,
                      <br />
                      CT GLEN BURNIE,
                      <br />
                      MD 21060, USA
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="border w-100 h-100 p-4 rounded mb-2 d-flex text-center align-items-center justify-content-center shadow">                    <p>
                      <i className="fa fa-phone fa-3x mb-3"></i>
                      <br />
                      +1 (410) 999-0273
                      
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="border w-100 h-100 p-4 rounded mb-2 d-flex text-center align-items-center justify-content-center shadow">
                    <p>
                      <i className="fa fa-envelope fa-3x mb-3"></i>
                      <br />
                      <a href="mailto:sales@uniquetsys.com">
                      support@scdiscountpromotion.com
                      </a>
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="border w-100 h-100 p-4 rounded mb-2 d-flex text-center align-items-center justify-content-center shadow">
                    <p>
                      <i className="fa fa-exclamation-circle fa-3x mb-3"></i>
                      <br />
                      SAM Information
                      <br />
                      Entity ID: M3XDXWTGJPV4
                      <br />
                      CAGE Code: 9MCU7 
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default Contact;
