import React from "react";
import backgroundTop from '../images/contact/contact-bg-1.jpg';

function HeaderContact() {
  return (
    <>
      <section
        class="hero-section-two bgs-cover overlay header-child"
        style={{ backgroundImage: `url(${backgroundTop})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-10 col-sm-11">
              <div class="hero-content text-white py-220 rpy-120">
                <h1 class="wow fadeInUp delay-0-4s mt-20">
                  Contact Us 
                </h1>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeaderContact;
