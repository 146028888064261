import React from "react";
import HeaderMain from "../includes/HeaderMain";
import ItemSlider from "../includes/ItemSlider"; 
import Clients from "../includes/Clients";
import Aboutus from "../includes/aboutus";

function Home() {  // Capitalized function name
    return (
        <>
            <HeaderMain />
            
            
            <Aboutus />
            <ItemSlider />
            <Clients />        
        </>
    );
}

export default Home;  // Exporting correctly capitalized component
